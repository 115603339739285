<template>
  <aside class="iv2-extra-nav position-fixed text-center d-xl-block">
    <nav class="iv2-extra-nav__sub-menu">
      <ul class="iv2-extra-nav__sub-list list-unstyled">
        <li v-for="nav in extra" :key="nav.id" class="iv2-extra-nav__sub-item">
          <router-link
            v-if="nav.link_type == 'internal'"
            :to="nav.link_href + lang + query"
            :target="nav.target_type"
            class="iv2-extra-nav__sub-link"
          >
            <i
              class="iv2-extra-nav__sub-item-icon d-block"
              :class="nav.item_icon"
            ></i>
            <span class="iv2-extra-nav__sub-item-text d-block">{{
              nav.item_name
            }}</span>
          </router-link>
          <a
            v-else-if="nav.link_type == 'external'"
            :href="nav.link_href"
            :target="nav.target_type"
            rel="noopener"
            class="iv2-extra-nav__sub-link"
          >
            <i
              class="iv2-extra-nav__sub-item-icon d-block"
              :class="nav.item_icon"
            ></i>
            <span class="iv2-extra-nav__sub-item-text d-block">{{
              nav.item_name
            }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
export default {
  props: {
    extra: Array,
  },

  data() {
    return {
      query: "",
      lang: "",
    };
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },
};
</script>

<style lang="scss">
.iv2-extra-nav {
  background-color: #61aa7f; // customize: カスタマイズ箇所

  .iv2-extra-nav__sub-menu {
    .iv2-extra-nav__sub-list {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      height: 100%;

      .iv2-extra-nav__sub-item {
        margin-bottom: 16px;
        width: 64px;
        margin: 0 7px;
        position: relative;
        color: #596063;

        &::after {
          content: "";
          display: block;
          height: 80%;
          border-right: 1px solid;
          position: absolute;
          right: -8px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:last-child {
          &::after {
            content: none;
          }
        }

        .iv2-extra-nav__sub-link {
          -webkit-transition: 0.3s;
          transition: 0.3s;
          display: block;
          color: #ffffff; // customize: カスタマイズ箇所

          &:hover {
            text-decoration: none;
            opacity: 0.5;
          }
        }

        .iv2-extra-nav__sub-item-icon {
          font-size: 1.2rem;
          margin-bottom: 6px;
        }

        .iv2-extra-nav__sub-item-text {
          font-size: 10px;
          font-weight: 400;
          line-height: 1.3;
        }
      }
    }
  }
}
</style>
